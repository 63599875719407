import React from "react";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import {
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	Row,
	Stack,
	DropdownButton,
} from "react-bootstrap";
import { parseNestedHTML } from "../Utils";
import HTMLReactParser from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import { ValidationError } from "@formspree/react";
import SafeHtmlParser from "../components/safe-html-parser";
import ReactPlayer from "react-player";
import DesktopLogo from "../images/background elements.svg";
import PricingImage from "../images/price-fright-night.svg";
import { MdOpacity } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import ReviewSection from "../components/review-section";
import ContactForm from "../components/contactForm";
import XmasContactForm from "../components/xmas-contact-form";
import HalloweenContactForm from "../components/halloween-contact-form";
import { BgImage } from "gbimage-bridge";

const NinjaHalloween = () => {
	const data = useStaticQuery(graphql`
		query {
			bgImg: wpMediaItem(title: { eq: "4930" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpHalloweenParty {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}

				halloweenPartyFields {
					pricing {
						price
						title
						underTitleText
						services {
							label
						}
					}
					btnText
					socialBannerTitle
					heroSection {
						title
						smallHeading
						description
						buttonText
					}
					glimpse {
						btnText
						image1 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image4 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image5 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}

					reviews {
						buttonText
						title
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const NBPPage = data.wpHalloweenParty.halloweenPartyFields;
	const seoFields = data.wpHalloweenParty.seoFields;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Ninja Fright Night",
				item: {
					url: `${siteUrl}/fright-night`,
					id: `${siteUrl}/fright-night`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/fright-night`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal-halloween">
						<div
							className="  d-none d-xxl-block "
							style={{
								overflow: "hidden",
								zIndex: 0,
								minWidth: "100%", // Ensure the video container spans the full width
							}}
						>
							<ReactPlayer
								loop
								playing
								muted
								playsinline
								className=" h-100 "
								url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/09/778547_Abstract_Animation_1920x1080-1.mp4"
								style={{
									minWidth: "100%",
								}}
							/>
						</div>
						<div
							className=" d-xxl-none video-halloween bottom-0 start-50 translate-middle-x"
							style={{
								overflow: "hidden",
								zIndex: 0,
								minWidth: "100%", // Ensure the video container spans the full width
							}}
						>
							<ReactPlayer
								loop
								playing
								muted
								playsinline
								className=" h-100 "
								url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/09/778547_Abstract_Animation_1920x1080.mp4"
								style={{
									minWidth: "100%",
								}}
							/>
						</div>
						<div
							style={{
								background: "rgba(191, 0, 43, 0.30)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
								overflow: "hidden",
								zIndex: 1,
							}}
							className="position-absolute top-0 start-0 h-100 w-100"
						>
							<Container
								style={{ zIndex: 2 }}
								className="h-100 position-relative"
							>
								<Row className="align-items-center justify-content-center h-100">
									<Col lg={10} xl={8} className="text-center">
										<div className="h-100 ">
											<p
												className="fs-1 text-white emilys"
												dangerouslySetInnerHTML={{
													__html: NBPPage.heroSection.smallHeading.replace(
														/(\d+)(st)/g,
														"$1<sup>$2</sup>"
													),
												}}
											></p>
											<div className="stylishText text-uppercase text-center center-text ">
												<SafeHtmlParser
													htmlContent={NBPPage.heroSection.title}
												/>
											</div>
											<p className="text-white fs-5 pb-0 mb-0">
												{NBPPage.heroSection.description}
											</p>
											<Button
												variant="white"
												href="#form"
												className="text-primary text-uppercase  my-3 my-lg-4 fs-5 px-xl-4 py-2"
											>
												{NBPPage.heroSection.buttonText}
											</Button>
											<p className="text-white">{NBPPage.heroSection.date}</p>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</section>

				<section>
					<BgImage
						style={{ backgroundPosition: "bottom" }}
						Tag="section"
						className=" py-5 py-lg-7"
						image={getImage(
							data.bgImg.localFile?.childImageSharp?.gatsbyImageData
						)}
					>
						<div
							style={{
								background: "rgba(191, 0, 43, 0.30)",

								zIndex: 1,
							}}
							className="position-absolute w-100 h-100 start-0 top-0"
						></div>
						<Container className="position-relative" style={{ zIndex: 2 }}>
							<Row className="my-lg-5 justify-content-center">
								<Col>
									<div
										className="p-3 p-md-5 h-100"
										style={{
											backgroundColor: "rgba(191, 0, 43, 0.4)",
											borderRadius: 8,
										}}
									>
										<Row>
											<Col xs={12} md={true}>
												<h3 className="fs-2 mt-3  text-uppercase emilys">
													{NBPPage.pricing.title}
												</h3>
												{NBPPage.pricing.underTitleText && (
													<p className="pb-0 mb-0 text-white">
														{NBPPage.pricing.underTitleText}
													</p>
												)}
												<p className="text-white pt-3 pb-0 fs-5 karla-bold">
													{NBPPage.pricing.price}
												</p>
												<Button
													variant="white"
													href="#form"
													className="text-primary text-uppercase w-100 w-md-auto my-3 my-lg-4 fs-5 px-xl-4 py-2"
												>
													{NBPPage.btnText}
												</Button>
											</Col>
											<Col xs={12} md={true}>
												<div className="d-md-none">
													{NBPPage.pricing.services.map(({ label }, i) => (
														<Stack
															direction="horizontal"
															className="align-items-start"
															gap={1}
															key={i}
														>
															<NinjaStarSVG
																style={{ minWidth: "15px", maxWidth: "15px" }}
															/>
															<p className="text-white pt-1">{label}</p>
														</Stack>
													))}
												</div>
												<div className="d-none d-md-block">
													{NBPPage.pricing.services.map(({ label }, i) => (
														<Stack
															direction="horizontal"
															className="align-items-start"
															gap={2}
															key={i}
														>
															<NinjaStarSVG
																style={{ minWidth: "25px", maxWidth: "25px" }}
															/>
															<p className="text-white pt-1 fs-5">{label}</p>
														</Stack>
													))}
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</BgImage>
				</section>

				<section>
					<div
						className="py-3"
						style={{
							backgroundColor: "#BF002B",
						}}
					>
						<Container>
							<Row>
								<Col
									lg={6}
									className="position-relative text-center p-0 session3"
								>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-center d-none d-md-flex justify-content-center justify-content-md-start "
									>
										<div className="d-flex align-items-center">
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black  d-inline-block me-3  fs-2 social-icon"
												href="https://www.instagram.com/ninjaeastbourne/"
											>
												<AiFillInstagram />
											</a>
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
												href="https://www.facebook.com/NinjaArenaEastbourne"
											>
												<FaFacebookSquare />
											</a>
										</div>
										<div className="title stylishText">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
										</div>
									</Stack>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-start d-md-none justify-content-center justify-content-md-start "
									>
										<div className="title stylishText position-relative">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
											<div className="d-flex me-3 position-absolute top-0 end-0 align-items-center">
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black  d-inline-block me-3  fs-2 social-icon"
													href="https://www.instagram.com/ninjaeastbourne/"
												>
													<AiFillInstagram />
												</a>
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
													href="https://www.facebook.com/NinjaArenaEastbourne"
												>
													<FaFacebookSquare />
												</a>
											</div>
										</div>
									</Stack>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<section className="pt-5 pt-lg-7">
					<Container>
						<h2 className="display-5 pb-4 text-center text-uppercase">
							{NBPPage.reviews.title}
						</h2>

						<ReviewSection fromIndex={0} toIndex={6} black />
						<div className="flex d-flex justify-content-center m-3">
							<Button
								target="_blank"
								rel="noreferrer"
								href="https://www.google.com/maps/place/Ninja+Arena,+Eastbourne/@50.769601,0.28568,14z/data=!4m8!3m7!1s0x47df7341975be679:0x63c07fe02c3335f3!8m2!3d50.7696006!4d0.2856804!9m1!1b1!16s%2Fg%2F11t5y526t4?hl=en&entry=ttu"
								className="text-white w-100 w-md-auto text-uppercase mt-4 fs-5 px-4 py-2"
							>
								{NBPPage.reviews.buttonText}
							</Button>
						</div>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="g-3">
							<Col lg={6}>
								<div
									className="h-100 w-100"
									style={{ borderRadius: "16px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={
											NBPPage.glimpse.image1?.node.localFile?.childImageSharp
												?.gatsbyImageData
										}
										className="w-100 h-100"
										alt={NBPPage.glimpse.image1?.node?.altText}
									/>
								</div>
							</Col>
							<Col lg={6}>
								<Row className="g-3">
									<Col xs={6}>
										<div
											className="h-100"
											style={{ borderRadius: "16px", overflow: "hidden" }}
										>
											<GatsbyImage
												image={
													NBPPage.glimpse.image2?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image2?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image3?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image3?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image4?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image4?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image5?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image5?.node?.altText}
											/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									href="#form"
									className="text-white w-100 w-md-auto text-uppercase mt-5 fs-5 px-4 py-2"
								>
									{NBPPage.glimpse.btnText}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="form" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase text-center">
									enquire now
								</h2>

								<HalloweenContactForm />
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</div>
	);
};

export default NinjaHalloween;
